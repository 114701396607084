<template>
  <div style="margin-bottom: 34px">
    <div class="list-header">
      <div class="title">
        <i class="el-icon-arrow-down"></i>
        {{ category.name }}
      </div>
      <el-button type="text" v-if="status === 0" @click="onMore"
        >查看更多</el-button
      >
      <el-button type="text" v-else @click="onClose">收起</el-button>
    </div>
    <div class="list-content">
      <template v-for="(item, index) in list">
        <div
          class="list-item"
          :key="item && item._id"
          v-if="(index < 3 && status === 0) || status === 1"
        >
          <div class="new" v-if="item.IsNew">NEW</div>
          <div class="update" v-if="item.IsRecentlyUpdated">UPDATED</div>
          <img class="image" alt="" :ref="`item-picture-${item._id}`" />
          <div class="item-info">
            <div class="item-info-row">
              <div class="item-info-title">{{ item.Name }}</div>
              <div class="item-info-text">{{ item.TriggerType }}</div>
            </div>
            <div class="item-info-row">
              <div class="item-info-user">创建者：{{ item.CreatedByUser }}</div>
              <div class="item-info-date">
                更新时间： {{ item.LastModifiedDate }}
              </div>
            </div>
          </div>

          <div class="item-content" :title="item.TemplateDescription">
            {{ item.TemplateDescription || "无描述" }}
          </div>
          <div class="item-footer">
            <el-button
              size="small"
              round
              type="primary"
              @click="addPlan(item._id)"
              v-if="
                menuPermission &&
                menuPermission.运营中心 &&
                menuPermission.运营中心.create
              "
              >创建我的运营活动</el-button
            >
            <div style="display: flex">
              <span @click="editTemplate(item._id)" class="btnEdit"
                >修改模板</span
              >
              <i
                class="iconfont icon-delete iconBtn"
                title="删除"
                @click="delTemplate(item._id)"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
    <el-pagination
      class="pagination"
      layout="prev, pager, next"
      :total="pagination.totalCount"
      :current-page="pagination.currentPage"
      :page-size="pagination.pageSize"
      @current-change="handlePage"
      v-if="status === 1 && list.length"
    >
    </el-pagination>
    <edit-drawer
      :exist.sync="existDrawer"
      :saveType="editType"
      @success="handleSaved"
      :templateId="templateId"
    />

    <edit-template
      :visible.sync="editTemplateVisible"
      :CampaignId="templateId"
      :type="'edit'"
      @success="successEditTemplate"
    ></edit-template>
    <el-dialog title="删除确认" :visible.sync="delConfirmShow" width="30%">
      <span>确定删除该活动模板吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delConfirmShow = false" type="text">取 消</el-button>
        <el-button type="primary" @click="handleDelete" round>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EditDrawer from "../../plan/components/edit-drawer";
import EditTemplate from "../../plan/components/save-template.vue";
import { apiGetCampaignPermission } from "@/api/auth";
import { apiDeletePlan } from "@/api/plan";
import { getImgUrl } from "@/api/upload";

export default {
  components: {
    EditDrawer,
    EditTemplate,
  },
  props: {
    category: {
      type: Object,
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          pageSize: 12,
          totalCount: {
            default: 0,
          },
          currentPage: 1,
        };
      },
    },
    list: [],
  },
  data() {
    return {
      status: 0, //0 收起，1展开
      existDrawer: false,
      editType: "templateAdd",
      templateId: null,
      editTemplateVisible: false,
      delConfirmShow: false,
    };
  },
  computed: {
    ...mapGetters({
      menuPermission: "common/menuPermission",
    }),
  },
  watch: {
    list(newVal) {
      if (newVal) {
        this.getImgList();
      }
    },
  },
  methods: {
    handlePage(index) {
      this.$emit("changeCurrentPage", index);
    },
    more() {
      this.status = 1;
    },
    onMore() {
      this.status = 1;
      this.$emit("onMore", this.category);
    },

    close() {
      this.status = 0;
    },
    onClose() {
      this.status = 0;
      this.$emit("onClose");
    },
    addPlan(templateId) {
      this.templateId = templateId;
      this.existDrawer = true;
    },
    // 创建成功我的运用活动
    handleSaved() {
      this.$router.push({
        path: "/plan/list",
      });
    },
    // 编辑模板
    editTemplate(id) {
      // 请求编辑权限
      apiGetCampaignPermission(id, {
        target: "edit",
      }).then((res) => {
        if (res.PermissionEdit) {
          this.templateId = id;
          this.editTemplateVisible = true;
        } else {
          this.$message.error("无编辑权限");
        }
      });
    },
    delTemplate(id) {
      apiGetCampaignPermission(id, {
        target: "delete",
      }).then((res) => {
        if (res.PermissionDelete) {
          this.delConfirmShow = true;
          this.templateId = id;
        } else {
          this.$message.error("无删除权限");
        }
      });
    },
    successEditTemplate() {
      this.editTemplateVisible = false;
      this.$emit("refresh");
    },
    handleDelete() {
      apiDeletePlan(this.templateId).then(() => {
        this.delConfirmShow = false;
        this.$emit("refresh");
      });
    },
    getImgList() {
      this.$nextTick(function () {
        this.list.forEach((item) => {
          const ref = this.$refs[`item-picture-${item._id}`];
          if (ref && ref[0]) {
            getImgUrl(item.TemplateCoverPicture).then((res) => {
              ref[0].src = res.signUrl;
            });
          }
        });
      })
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-pagination button:disabled {
  background: transparent;
}
/deep/ .el-pager li {
  background: transparent;
}
/deep/ .el-pagination .btn-next,
/deep/.el-pagination .btn-prev {
  background: transparent;
}
.list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 66px;
  padding-right: 12px;
  .title {
    font-size: 20px;
    font-weight: 500;
  }
  .el-icon-arrow-down {
    margin-right: 10px;
  }
}

.list-content {
  display: flex;
  margin: -10px;
  flex-wrap: wrap;
  flex-direction: row;

  .list-item {
    margin: 10px;
    padding: 20px;
    width: calc((100% - 60px) / 3);
    // height: 356px;
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.04),
      0px 3px 6px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    box-sizing: border-box;
    position: relative;
    .new,
    .update {
      position: absolute;
      top: 30px;
      right: 20px;
      /* Alerts / Success 500 */
      padding: 4px 8px;
      background: #19b977;
      /* M - 02 dp */
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04),
        0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 3px rgba(0, 0, 0, 0.06);
      border-radius: 6px 0px 0px 6px;
      font-size: 10px;
      color: #ffffff;
    }
    .update {
      background: #f09c0a;
    }
    .image {
      width: 100%;
      height: 120px;
      border-radius: 12px;
      margin-bottom: 16px;
    }
    .item-info {
      &-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
      }
      &-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
      }
      &-user {
        font-size: 12px;
        line-height: 19px;
      }
      &-text {
        font-size: 12px;
        line-height: 19px;
      }
      &-date {
        font-size: 12px;
        line-height: 19px;
      }
    }
    .item-content {
      height: 65px;
      margin: 10px 0 16px;
      font-size: 14px;
      line-height: 22px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
    .item-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.btnEdit {
  cursor: pointer;
  color: #0483ec;
}

.iconBtn {
  cursor: pointer;
  margin-left: 10px;
  color: #9a9a9e;
}
</style>
