<template>
  <div>
    <content-header :title="title" :breadList="['运营中心', '活动模板库']">
    </content-header>

    <div class="operation">
      <div class="category">
        <el-button
          size="small"
          round
          :type="-1 === categorySelect.id ? 'primary' : 'default'"
          @click="handleCategory({ id: -1, name: 'ALL' })"
          >ALL</el-button
        >
        <el-button
          size="small"
          round
          :type="item.id === categorySelect.id ? 'primary' : 'default'"
          v-for="item in categoryList"
          :key="item.name"
          @click="handleCategory(item)"
          >{{ item.name }}</el-button
        >
      </div>
      <el-input
        placeholder="搜索活动模板..."
        v-model="searchVal"
        style="width: 360px; margin: 24px 0"
        size="small"
        @change="search"
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
    </div>

    <div v-loading="loading">
      <template v-for="category in categoryList">
        <TemplateListItem
          :key="category.id"
          :category="category"
          @refresh="onRefresh"
          @changeCurrentPage="changeCurrentPage"
          @onMore="onMore"
          @onClose="handleCategory({ id: -1, name: 'ALL' })"
          v-show="
            (category.id === categorySelect.id && categorySelect.id !== -1) ||
            categorySelect.id === -1
          "
          :ref="category.name"
          :list="getList(category.name)"
          :pagination="pagination"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ContentHeader from "@/views/layout/content-header";
import TemplateListItem from "./components/template-list-item.vue";
import { apiGetTemplateList } from "@/api/plan";
import { debounce } from "lodash";

export default {
  components: {
    ContentHeader,
    TemplateListItem,
  },
  data() {
    return {
      title: this.$route.meta.title,
      categorySelect: {
        name: "ALL",
        id: -1,
      }, // 选择分类的id
      searchVal: "",
      pagination: {
        pageSize: 12,
        totalCount: 0,
        currentPage: 0,
      },
      list: [], // 数据列表
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      campaign: "common/campaign",
    }),
    field() {
      const name = "TemplateType";
      const field = this.campaign.find((item) => item.APIName === name);
      return field || {};
    },
    categoryList() {
      const list = [];
      if (this.field) {
        (this.field.PicklistOptions || []).forEach((val) => {
          list.push({
            name: val,
            id: val,
          });
        });
        return list;
      } else {
        return list;
      }
    },
  },
  watch: {
    categoryList(newVal) {
      if (newVal) {
        this.getTemplateList();
      }
    },
  },
  methods: {
    getList(name) {
      // ALL
      if (this.categorySelect.id === -1) {
        let list = [];
        this.list.map((item) => {
          if (item.type === name) {
            list = list.concat(item.campaignTemplates);
          }
        });
        return list;
      } else {
        return this.list;
      }
    },
    // 切换分类
    handleCategory(item) {
      if (item.id === this.categorySelect.id) return;
      // 如果现在选择的不是all，则收起现在的
      if (this.categorySelect.id !== -1) {
        this.$refs[this.categorySelect.name][0].close();
      }
      this.categorySelect = item;
      this.list = [];
      this.pagination.currentPage = 0;
      this.pagination.totalCount = 0;

      // 如果点的不是all, 则展开更多
      if (item.id !== -1) {
        this.getTypeTemplateList();
        this.$refs[item.name][0].more();
      } else {
        this.getTemplateList();
      }
    },
    // 查看更多
    onMore(category) {
      this.categorySelect = category;
      if (this.categorySelect.id !== -1) {
        this.list = [];
        this.pagination.currentPage = 0;
        this.pagination.totalCount = 0;
        this.getTypeTemplateList();
      }
    },

    // 获取所有模板首页
    getTemplateList() {
      this.list = [];
      this.pagination.currentPage = 0;
      this.pagination.totalCount = 0;
      this.loading = true;

      const fetching = [];

      this.categoryList.forEach((item) => {
        const fetch = apiGetTemplateList({
          keyword: this.searchVal,
          type: item.id,
          offset: 0,
          limit: 3,
        });
        fetching.push(fetch);
      });

      Promise.all(fetching)
        .then((res) => {
          const data = [];
          res.forEach((item, i) => {
            const obj = {
              type: this.categoryList[i]["id"],
              campaignTemplates: item.campaignTemplates,
            };
            data.push(obj);
          });
          this.list = data;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },

    // 获取某个分类下的模板
    getTypeTemplateList() {
      this.loading = true;
      apiGetTemplateList({
        keyword: this.searchVal,
        type: this.categorySelect.id,
        offset: ((this.pagination.currentPage === 0 ? 1 : this.pagination.currentPage) -1)*this.pagination.pageSize,
        limit: this.pagination.pageSize,
      })
        .then((res) => {
          this.pagination.totalCount = res.meta.total_count;
          this.list = res.campaignTemplates || [];
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    // 切换分页
    changeCurrentPage(currentPage) {
      this.pagination.currentPage = currentPage;
      this.getTypeTemplateList();
    },
    search: debounce(function () {
      if (this.categorySelect.id === -1) {
        this.getTemplateList();
      } else {
        this.getTypeTemplateList();
      }
    }, 100),
    onRefresh() {
      this.search();
    },
  },
  created() {
    this.getTemplateList();
  },
};
</script>

<style lang="less" scoped>
.operation {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #dddddf;
}
.category {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
